import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Header from "../components/header"
import Footer from "../components/footer"
import { StaticImage } from "gatsby-plugin-image"

const Layout = ({ isHomePage, children }) => {

  const { wpPage } = useStaticQuery(graphql`
      {
        wpPage(isFrontPage: {eq: true}) {
        content
        title
        uri
      }
    }
    `
  )

  return (
    <div className="global-wrapper" data-is-root-path={isHomePage}>
      
      <Header />

      <section className="hero">
        <div className="hero-body">
        <StaticImage 
                src="../img/cannon-beach.png"
                alt=""
                id="sitelogo" 
                loading="blurred"
         />
        </div>
      </section>


      <Footer />
    </div>
  )
}

export default Layout
