import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby"
import UniversalLink from "./UniversalLink"
import { StaticImage } from "gatsby-plugin-image"

const NavLogo = () =>{

    const { wp } = useStaticQuery(graphql`
    {
      wp {
        generalSettings {
          title
          description
        }
      }
    }
  `)

  return (

              <UniversalLink to="/" className="navbar-item">
                <h1 className="is-size-1">{ wp.generalSettings.title }</h1>
              </UniversalLink>

  )

}

export default NavLogo