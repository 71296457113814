import React, { useState } from "react";
import Menu from "./Header/Menu"
import NavButton from "./Header/NavButton";
import NavLogo from "./Header/NavLogo";
//import ToggleIcon from "../assets/svg/toggle.inline.svg"

const Header = ({ pageContext, ...props }) => {

  const [menuActive, toggleMenu] = useState(/* this sets the default value */ false)



  
  return (
    <header id="site-header" role="banner">
      <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
            <NavLogo />
            <NavButton toggle={toggleMenu} isActive={menuActive} />
      </div>

        
          <Menu toggle={toggleMenu} isActive={menuActive} />

      </nav>
    </header>
  )
}

export default Header
