import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import UniversalLink from "./UniversalLink"
import { FlatListToHierarchical } from "./FlatListToHierarchical"

const MenuLoop = ({ menuItems }) => {
  return(
    <div className="navbar-start">
      {menuItems.map((menuItem, index) => {
        return (
          <div
            key={index}
            className={menuItem.routes.length > 0 ? "navbar-item has-dropdown is-hoverable" : "navbar-item" }
          >
            <UniversalLink to={menuItem.path} className={menuItem.routes.length > 0 ? "navbar-link" : "navbar-item"}>
              {menuItem.title}
            </UniversalLink>
            {menuItem.routes.length > 0 && (
              <div className={"navbar-dropdown"}>
                <SubMenuLoop headerMenu={menuItem.routes} />
              </div>
            )}
          </div>
        )
      })}
      </div>
  )
}

const SubMenuLoop = ({ headerMenu }) => {
  return (
    <div>
    {headerMenu.map((menuItem, index) => {
      return (
      <UniversalLink key={index} to={menuItem.path} className="navbar-item">
        {menuItem.title}
      </UniversalLink>
      )
    })}
    </div>
  )
}

const Menu = ({toggle, isActive}) => {
  const wpMenu = useStaticQuery(graphql`
    {
      allWpMenuItem(
        sort: { fields: order, order: ASC }
        filter: { menu: { node: { slug: { eq: "primary" } } } }
      ) {
        nodes {
          id
          title: label
          path
          target
          parent: parentId
        }
      }
    }
  `)

 // console.log("Raw data:", wpMenu.allWpMenuItem.nodes)
  const headerMenu = FlatListToHierarchical(wpMenu.allWpMenuItem.nodes, {
    idKey: "id",
    childrenKey: "routes",
    parentKey: "parent",
  })
  //console.log("headerMenu: ", headerMenu)

  return (
    <div id="primaryMenu" className={`navbar-menu ${isActive ? `is-active` : ``}`}>

         {headerMenu.length > 0 && <MenuLoop menuItems={headerMenu}></MenuLoop>}

    </div>
  )
}

export default Menu
