import React, { useState } from "react";
//import ToggleIcon from "../assets/svg/toggle.inline.svg"

const Footer = ({ pageContext, ...props }) => {

  const [menuActive, toggleMenu] = useState(/* this sets the default value */ false)



  
  return (
    <footer className=".footer">
    <div className="content has-text-centered">
      © {new Date().getFullYear()}, Built with
      {` `}
      <a href="https://www.gatsbyjs.com">Gatsby</a>
      {` `}
      And <a href="https://wordpress.org/">WordPress</a>
      </div>
    </footer>
  )
}

export default Footer
