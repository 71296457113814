import React from "react";

const NavButton = ({ toggle, isActive }) => {
  return (
    <a role="button" className={`navbar-burger ${isActive ? `is-active` : `` }`} aria-label="menu" aria-expanded="false" data-target="primaryMenu" onClick={() => toggle(!isActive)}>
    <span aria-hidden="true">
    </span>
    <span aria-hidden="true">
    </span>
    <span aria-hidden="true">
    </span>
</a>
  )
}
export default NavButton